import React from "react"
import { BiLogOutCircle } from "react-icons/bi"
import { CiUser } from "react-icons/ci"
import { Link } from "react-router-dom"
const Employee = () => {
	return (
		<>
			<li>
				<Link className='dropdown-item' to='/profile'>
					<CiUser /> Profile
				</Link>
			</li>
			<li>
				<Link className='dropdown-item' to='/login'>
					<BiLogOutCircle
						style={{
							marginRight: "5px",
						}}
					/>
					Logout
				</Link>
			</li>
		</>
	)
}

export default Employee
