import React from "react"
const Page = ({ page }) => {
	return (
		<div className='p-3'>
			<p className='page-name-text'>{page?.title}</p>
			<div dangerouslySetInnerHTML={{ __html: page?.body }} />
		</div>
	)
}

export default Page
