import React from "react"
import BurgerIcon from "../BurgerIcon/BurgerIcon"
import NavTop from "./NavTop"
import NavMiddle from "./NavMiddle"
import NavBottom from "./NavBottom"

function Header() {
	return (
		<>
			<div
				className='webNav'
				// style={{ maxHeight: "150px" }}
			>
				<NavTop />
				<NavMiddle />
				<NavBottom />
			</div>

			<div
				className='container-fluid burger justify-content-center'
				style={{ backgroundColor: "var(--blue)" }}
			>
				<BurgerIcon />
			</div>
		</>
	)
}

export default Header
