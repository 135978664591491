import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsHeart } from "react-icons/bs";
import { AiFillSetting } from "react-icons/ai";
import Accounts from "./Dropdowns/NavMiddle/Accounts";
import Search from "./Search/Search";
import { logout } from "../../redux/reducers/auth";
import { BiLogOutCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { CiUser } from "react-icons/ci";

const NavMiddle = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const user = useSelector((state) => state.auth.user);

  const productsCart = useSelector((state) => state.cart?.productsCart);
  const wholesaleCart = useSelector((state) => state.cart?.wholesaleCart);
  // console.log(user);
  return (
    <>
      {/* second navbar */}
      <div className="container-fluid px-5 py-2 d-flex justify-content-between bg-white">
        <div className="d-flex w-100 align-items-center gap-3 justify-content-between">
          <Link className="navbar-brand text-dark" to="">
            <p className="h1 logo-font">ASWAAQ SINDBAD</p>
          </Link>
          <Search />
        </div>
        <div className="d-flex fsSmall align-items-center gap-5 ms-2 text-dark">
          <Link className="nav-link d-flex align-items-center gap-2" to="/cart">
            <AiOutlineShoppingCart />
            Cart
            <div
              className="bg-dark text-white px-2 py-1 rounded-circle m-0"
              style={{
                fontSize:"10px",
                position: "relative",
                bottom: 10,
                right: 5,
              }}
            >
              <p className="m-0 p-0">{productsCart?.length + wholesaleCart?.length}</p>
            </div>
          </Link>
          <Link className="nav-link d-flex align-items-center gap-2" to="">
            <BsHeart />
            Wishlist
          </Link>
          <Link
            className="nav-link d-flex align-items-center gap-2 pe-4 border-end"
            to="/settings"
          >
            <AiFillSetting />
            Settings
          </Link>

          <Accounts />
        </div>
      </div>
    </>
  );
};

export default NavMiddle;
