import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

export default function Pagination(props) {
	const { setPageNumber, count } = props
	const [activePage, setActivePage] = useState(1)

	useEffect(() => {
		setPageNumber(activePage)
	}, [activePage])

	const pages = Math.round(count / 10)
	return (
		<div className='d-flex flex-wrap gap-1 my-4'>
			<button
				className='py-2 px-3 border-0 text-dark rounded-1 '
				style={{ backgroundColor: "#DFE9F0" }}
				onClick={() => {
					setActivePage(activePage > 1 ? activePage - 1 : activePage)
				}}
			>
				{`<`}
			</button>
			{Array(pages !== 0 ? pages : 1)
				.fill(0)
				.map((item, index) => {
					return (
						<button
							className={
								activePage == index + 1
									? "py-2 px-3 border-0 text-dark rounded-1 pageActive"
									: "py-2 px-3 border-0 text-dark rounded-1"
							}
							style={{ backgroundColor: "#DFE9F0" }}
							onClick={() => {
								setActivePage(index + 1)
							}}
						>
							{index + 1}
						</button>
					)
				})}
			<button
				className='py-2 px-3 border-0 text-dark rounded-1 '
				style={{ backgroundColor: "#DFE9F0" }}
				onClick={() => {
					setActivePage(activePage < 6 ? activePage + 1 : activePage)
				}}
			>
				{`>`}
			</button>
		</div>
	)
}
