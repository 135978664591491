import React from "react"
import { FaLinkedinIn } from "react-icons/fa"
import { FaFacebookF } from "react-icons/fa"
import { AiOutlineTwitter } from "react-icons/ai"
import { Link } from "react-router-dom"

const Footer = () => {
	return (
		<div className='footer bg-white px-md-5'>
		
			<div className='d-flex flex-column gap-3 flex-md-row flex-wrap py-md-5 px-md-5 px-4'>
				<div className='px-md-5 d-flex flex-column gap-2'>
					<p
						className='fw-bolder m-0'
						style={{
							color: "#012A4A",
						}}
					>
						Information
					</p>
					<Link to='' className='nav-link'>
						FAQ
					</Link>{" "}
					<Link to='' className='nav-link'>
						Blog
					</Link>{" "}
					<Link to='' className='nav-link'>
						Support
					</Link>{" "}
				</div>
				<div className='px-md-5 d-flex flex-column gap-2'>
					<p className='fw-bolder m-0' style={{ color: "#012A4A" }}>
						Company
					</p>
					<Link to='' className='nav-link'>
						About us
					</Link>{" "}
					<Link to='' className='nav-link'>
						Careers
					</Link>{" "}
					<Link to='' className='nav-link'>
						Contact us
					</Link>{" "}
				</div>
				<div className='px-md-5 d-flex flex-column gap-2'>
					<p
						className='fw-bolder m-0'
						style={{
							color: "#012A4A",
						}}
					>
						Services
					</p>
					<Link to='' className='nav-link text-muted'>
						Become a seller
					</Link>{" "}
					<Link to='' className='nav-link text-muted'>
						Request a Product
					</Link>{" "}
					<Link to='' className='nav-link text-muted'>
						Finance
					</Link>{" "}
				</div>
			</div>
			<hr />
			<div className='d-flex py-3 gap-3 flex-wrap flex-md-row flex-column justify-content-center align-items-center justify-content-md-between w-100'>
				<p
					className='h1 logo-font'
					style={{
						color: "#01497C",
					}}
				>
					ASWAAQ SINDBAD
				</p>
				<div className='d-flex flex-wrap justify-content-center gap-4 align-items-center '>
					<Link to='' className='nav-link'>
						Terms
					</Link>
					<Link to='' className='nav-link'>
						Privacy
					</Link>
					<Link to='' className='nav-link'>
						Cookies
					</Link>
				</div>
				<div className='d-flex justify-content-center gap-4 align-items-center'>
					<Link
						to=''
						className='rounded-circle py-1 px-2'
						style={{
							border: "1.71788px solid rgba(1, 73, 124, 0.1)",
						}}
					>
						<FaLinkedinIn style={{ color: "rgba(1, 73, 124, 1)" }} />
					</Link>
					<Link
						to=''
						className='rounded-circle py-1 px-2'
						style={{
							border: "1.71788px solid rgba(1, 73, 124, 0.1)",
						}}
					>
						<FaFacebookF style={{ color: "rgba(1, 73, 124, 1)" }} />
					</Link>
					<Link
						to=''
						className='rounded-circle py-1 px-2'
						style={{
							border: "1.71788px solid rgba(1, 73, 124, 0.1)",
						}}
					>
						<AiOutlineTwitter style={{ color: "rgba(1, 73, 124, 1)" }} />
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Footer
