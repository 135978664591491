//import icons
import { FaFacebookF, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

const ContactDetails = ({company}) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <p className="title-content mb-4">Get in Touch</p>
      <div className="my-5 d-flex flex-column justify-content-center align-items-center">
        <p className="p-0 m-0 title-bold-contact">{company?.street1 ? company?.street1 : "No Street Available"} {company?.address_name ? company?.address_name : "No Address Available"} St.</p>
        <p className="p-0 m-0 fw-bold">{company?.city ? company?.city : "No City Available"} - {company?.country ? company?.country : "No Country Available"}</p>
      </div>
      <p className="fw-bold">{company?.phone_number ? company?.phone_number : "No Phone Number Available" }</p>
      <p className="my-3 fw-bold">{company?.website ? company?.website : "No Website Available"}</p>
      <div className="d-flex flex-column justify-content-center align-items-center gap-2 mt-5 mb-auto">
        <p className="p-0 m-0 title-content" style={{fontSize: 25}}>Join Us</p>
        <div className="d-flex gap-2">
          <a href={company?.instagram ? company?.instagram : "#"}
            className="bg-light rounded-5"
            style={{
              padding: "0.7rem 1rem",
            }}
          >
            <FaInstagram className="social-icon" color="#012A4A" />
          </a>
          <a href={company?.facebook ? company?.facebook : "#"}
            className="bg-light rounded-5"
            style={{
              padding: "0.7rem 1rem",
            }}
          >
            <FaFacebookF className="social-icon" color="#012A4A" />
          </a>
          <a href={company?.twitter ? company?.twitter : "#"}
            className="bg-light rounded-5"
            style={{
              padding: "0.7rem 1rem",
            }}
          >
            <FaTwitter className="social-icon" color="#012A4A" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
