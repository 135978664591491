import React from "react"
import { Link } from "react-router-dom"
import { CiUser } from "react-icons/ci"
import Employee from "./Accounts/LoggedIn/Employee"
import { useSelector } from "react-redux"
import auth from "../../../../redux/reducers/auth"
import Guest from "./Accounts/Guest"

const Accounts = () => {
	const user = useSelector((state) => state.auth.user)
	const isAuth = useSelector((state) => state.auth.isAuth)
	const employee = <></>
	return (
		<>
			<div className='dropdown'>
				<button
					className='btn p-0 fsSmall d-flex gap-2 align-items-center text-dark border-0 dropdown-toggle '
					type='button'
					id='dropdownMenuButton1'
					data-bs-toggle='dropdown'
					aria-expanded='false'
				>
					<CiUser />
					{/* Sign In */}
					{isAuth ? `Welcome: ${user?.name}` : "Sign In"}
				</button>
				<ul
					className='dropdown-menu'
					aria-labelledby='dropdownMenuButton1'
					style={{ fontSize: "14px", width: "228px" }}
				>
					{isAuth ? <Employee /> : <Guest />}
				</ul>
			</div>
		</>
	)
}

export default Accounts
