import { Skeleton } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';

const SkeletonLoading = () => (
  <div className="col-lg-4 d-flex flex-column ">
    {Array(5)
      .fill(0)
      .map(() => (
        <li>
          <Skeleton variant="text" width={230} height={30} />
        </li>
      ))}
  </div>
);

const NavBottom = () => {
  const categories = useSelector(
    (state) => state?.categories?.categories?.results
  );
  return (
    <>
      {/* third navbar */}
      <div
        className="container-fluid px-5 d-flex justify-content-start gap-5 align-items-center  px-3 py-3 text-light text-center "
        style={{ backgroundColor: '#efefef' }}>
        <Link className="nav-link fsSmall text-dark" to="">
          Home
        </Link>
        <div className="dropdown">
          <Link
            className="nav-link text-dark fsSmall d-flex gap-2 align-items-center dropdown-toggle"
            to=""
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            Categories
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-white"
            style={{
              boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
              borderRadius: '0px 10px 10px 10px',
            }}>
            <div className="row d-flex p-3" style={{ width: '850px' }}>
              {categories == undefined
                ? Array(6)
                    .fill(0)
                    .map(() => <SkeletonLoading />)
                : categories
                    ?.filter((category) => category.active)
                    .sort(
                      (a, b) => b.subcategories.length - a.subcategories.length
                    )
                    .map((cat, index) => {
                      return (
                        <div
                          className="col-lg-4 d-flex flex-column mb-3"
                          key={index}>
                          <li key={index}>
                            <Link
                              style={{
                                fontWeight: 700,
                                fontSize: '16.5px',
                                lineHeight: '25px',
                                color: '#012A4A',
                              }}
                              className="dropdown-item"
                              to={`/category/${slugify(cat.title)}`}>
                              {cat?.title}
                            </Link>
                          </li>
                          {cat?.subcategories?.map((subcat, index) => {
                            return (
                              <li key={index}>
                                <Link
                                  className="dropdown-item"
                                  to={`/sub_category/${slugify(subcat.slug)}`}
                                  style={{
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    color: '#6C757D',
                                  }}>
                                  {subcat?.title}
                                </Link>
                              </li>
                            );
                          })}
                        </div>
                      );
                    })}
            </div>
          </ul>
        </div>
        <a
          href="https://www.aswaaqsindibad.com/my-discounts"
          className="nav-link fsSmall text-dark">
          Takhfidaty
        </a>
        <a
          className="nav-link fsSmall text-dark"
          href="https://www.aswaaqsindibad.com/agriculture">
          MIGHTY Mozara'
        </a>
        <a
          className="nav-link fsSmall text-dark"
          href="https://www.aswaaqsindibad.com/electronics">
          Shara' Abdeaziz
        </a>
        <a
          className="nav-link fsSmall text-dark"
          href="https://www.aswaaqsindibad.com/car-spare-parts">
          AlTawfeqaia
        </a>
        <a
          className="nav-link fsSmall text-dark"
          href="https://www.aswaaqsindibad.com/mighty-market">
          MIGHTY Market
        </a>
        <a
          href="https://www.aswaaqsindibad.com/sports-fitness"
          className="nav-link fsSmall text-dark">
          Sports & Fitness
        </a>
      </div>
    </>
  );
};

export default NavBottom;
