import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineCheck, wrong } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getOrderStatus } from "../../../redux/reducers/orders";
import { STYLING } from "../OrdersCard/styling";

const TrackStage = (props) => {
  const { stage, success, current, done, type, currentIndex, cancel, item, status } =
    props;
  // console.log(type)
  const dispatch = useDispatch();
  const orderStatus = useSelector(
    (state) => state?.orders?.orderStatus?.results
  );

  useEffect(() => {
    dispatch(getOrderStatus());
  }, [dispatch]);

  const stages =
    type === "order"
      ? orderStatus
      : [
          { id: 1, status: "Requested" },
          { id: 2, status: "Required Files" },
          { id: 3, status: "In consideration" },
          { id: 4, status: `${status == "Accepted" ? "Accepted" : "Rejected"}` },
        ];

  const stageText = stages?.find((item) => item?.id === stage)?.status;
  // const date = done ? "15:30 | 15 DEC 2022" : "Pending"

  return (
    <Row
      className={`my-md-0 my-2 ${
        type !== "order" &&
        "d-flex flex-column justify-content-center align-items-center"
      }`}
    >
      <Col md={3} className="d-flex justify-content-center align-items-center ">
        <div
          className={`rounded-circle d-flex justify-content-center align-items-center shadow my-2`}
          style={{
            backgroundColor:
              (cancel && currentIndex == 6 || currentIndex == 4 && stageText == "Rejected")
                ? "#E07070"
                : success
                ? STYLING.colors.success
                : current
                ? STYLING.colors.primary
                : "#FFF",
            width: "32px",
            height: "32px",
          }}
        >
          {success ? (
           <p className="m-0 p-3"> <AiOutlineCheck color="white" size={20}/></p>
          ) : (
            <p
              className="m-0 p-5"
              style={{
                color: current ? "#fff" : STYLING.colors.primary,
                fontWeight: STYLING.font.bold,
              }}
            >
              {currentIndex}
            </p>
          )}
        </div>
      </Col>
      <Col
        className={`d-flex flex-column align-items-center gap-1 justify-content-center ${
          type == "order" && "align-items-md-start"
        }`}
        style={{ height: "fit-content" }}
      >
        <p
          className="mb-0 m-0 p-0 text-center"
          style={{
            fontWeight: STYLING.font.bold,
            fontSize: STYLING.font.size.medium,
            color: STYLING.colors.primary,
          }}
        >
          {stageText}
        </p>
      </Col>
    </Row>
  );
};

export default TrackStage;
