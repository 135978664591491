import * as React from "react"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { GiHamburgerMenu } from "react-icons/gi"
import { Link } from "react-router-dom"
import { CiUser } from "react-icons/ci"
import { BsSearch } from "react-icons/bs"
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai"
import { Slide } from "@mui/material"
import "./Navbar.css"
import { useSelector } from "react-redux"
import slugify from "react-slugify"
import Search from "../Header/Search/Search";

function TransitionLeft(props) {
	return <Slide direction='right' {...props} />
}

export default function BurgerIcon() {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const categories = useSelector(
		(state) => state?.categories?.categories?.results
	)
	return (
		<>
			<div className='d-flex justify-content-center gap-3 align-items-center w-100 py-4 px-sm-4'>
				<div className='burger_icon'>
					<Button
						id='fade-button'
						aria-controls={open ? "fade-menu" : undefined}
						aria-haspopup='true'
						aria-expanded={open ? "true" : undefined}
						onClick={handleClick}
						className='p-0'
						style={{ minWidth: "fit-content" }}
					>
						<GiHamburgerMenu
							className='p-0'
							style={{
								color: "#fff",
								fontSize: "30px",
							}}
						/>
					</Button>
					<Menu
						id='fade-menu'
						MenuListProps={{
							"aria-labelledby": "fade-button",
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						Transitioncomponent={TransitionLeft}
					>
						<div
							style={{
								width: "100%",
								color: "#fff",
								background: "var(--blue)",
								height: "101vh",
								overflow: "auto",
							}}
						>
							<MenuItem>
								<ul className='w-100'>
									<li className='nav-item dropdown d-flex justify-content-end'>
										<Link
											className='nav-link dropdown-toggle d-flex align-items-center  gap-2'
											to=''
											role='button'
											data-bs-toggle='dropdown'
											aria-expanded='false'
										>
											<CiUser />
											Sign in
										</Link>
										<ul
											className='dropdown-menu dropdown-menu-white py-3'
											style={{
												boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.1)",
												borderRadius: "0px 10px 10px 10px",
											}}
										>
											<li className='p-2'>
												<Link className='dropdown-item' to=''>
													Sign in
												</Link>
											</li>
											<li className='p-2'>
												<Link className='dropdown-item' to=''>
													Sign up as Employee
												</Link>
											</li>
											<li className='p-2'>
												<Link className='dropdown-item' to=''>
													Sign up as Company
												</Link>
											</li>
										</ul>
									</li>
								</ul>
							</MenuItem>
							<MenuItem>
								<Link
									className='navbar-brand'
									to=''
									style={{
										padding: "9px",
									}}
								>
									<p className='h1 pe-3 logo-font'>ASWAAQ SINDBAD</p>
								</Link>
							</MenuItem>
							<MenuItem>
								<Link
									className='nav-link'
									to=''
									style={{
										padding: "9px",
									}}
								>
									<small>Home</small>
								</Link>
							</MenuItem>
							<MenuItem>
								<li className='nav-item dropdown'>
									<Link
										style={{
											padding: "9px",
										}}
										className='nav-link fsSmall gap-2 d-flex  align-items-center dropdown-toggle'
										to=''
										role='button'
										data-bs-toggle='dropdown'
										aria-expanded='false'
									>
										Categories
									</Link>
									<ul
										className='dropdown-menu dropdown-menu-white'
										style={{
											boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.1)",
											borderRadius: "0px 10px 10px 10px",
										}}
									>
										<div
											style={{
												height: "300px",
												overflow: "auto",
											}}
											className='d-flex flex-column p-2'
										>
											<div className=' d-flex flex-column gap-1'>
												
												{categories?.filter(item => item?.subcategories?.length > 0)?.map((cat, index)  => {
										return (
											<div
												className='col-lg-4 d-flex flex-column mb-3'
												key={index}
											>
												<li key={index}>
													<Link
														style={{
															fontWeight: 700,
															fontSize: "16.5px",
															lineHeight: "25px",
															color: "#012A4A",
														}}
														className='dropdown-item'
														to={`/category/${slugify(cat.title)}`}
													>
														{cat?.title}
													</Link>
												</li>
												{cat?.subcategories?.map((subcat, index) => {
													return (
														<li key={index}>
															<Link
																className='dropdown-item'
																to={`/sub_category/${slugify(subcat.slug)}`}
																style={{
																	fontWeight: 500,
																	fontSize: "14px",
																	lineHeight: "21px",
																	color: "#6C757D",
																}}
															>
																{subcat?.title}
															</Link>
														</li>
													)
												})}
											</div>
										)
								  })}
											</div>
										</div>
									</ul>
								</li>
							</MenuItem>
							<MenuItem>
								<Link
									className='nav-link'
									to=''
									style={{
										padding: "9px",
									}}
								>
									<small>New Equipment</small>
								</Link>
							</MenuItem>
							<MenuItem>
								<Link
									className='nav-link'
									to=''
									style={{
										padding: "9px",
									}}
								>
									<small>Old Equipment</small>
								</Link>
							</MenuItem>
							<MenuItem>
								<Link
									className='nav-link'
									to=''
									style={{
										padding: "9px",
									}}
								>
									<small>Reduced Price</small>
								</Link>
							</MenuItem>
							<MenuItem>
								<Link
									className='nav-link'
									to=''
									style={{
										padding: "9px",
									}}
								>
									<small>Order Tracking</small>
								</Link>
							</MenuItem>
							<MenuItem>
								<Link
									className='nav-link'
									to=''
									style={{
										padding: "9px",
									}}
								>
									<small>Settings</small>
								</Link>
							</MenuItem>
							<hr className='mx-auto' style={{ width: "85%" }} />
							<MenuItem>
								<Link
									className='nav-link'
									to=''
									style={{
										padding: "9px",
									}}
								>
									<small>About Us</small>
								</Link>
							</MenuItem>
							<MenuItem>
								<Link
									className='nav-link'
									to=''
									style={{
										padding: "9px",
									}}
								>
									<small>Why Sell with Us</small>
								</Link>
							</MenuItem>
							<MenuItem>
								<Link
									className='nav-link'
									to=''
									style={{
										padding: "9px",
									}}
								>
									<small>Demand Equipment</small>
								</Link>
							</MenuItem>
							<hr className='mx-auto' style={{ width: "85%" }} />

							<MenuItem>
								<li className='nav-item dropdown'>
									<Link
										style={{
											padding: "9px",
										}}
										className='nav-link fsSmall d-flex gap-2 align-items-center dropdown-toggle pe-5'
										to=''
										role='button'
										data-bs-toggle='dropdown'
										aria-expanded='false'
									>
										<small> USD $</small>
									</Link>
									<ul className='dropdown-menu dropdown-menu-white'>
										<li>
											<Link className='dropdown-item' to=''>
												LE
											</Link>
										</li>
										<li>
											<Link className='dropdown-item' to=''>
												UAE
											</Link>
										</li>
										<li>
											<Link className='dropdown-item' to=''>
												KSA
											</Link>
										</li>
									</ul>
								</li>
								<li className='nav-item dropdown'>
									<Link
										style={{
											padding: "9px",
										}}
										className='nav-link fsSmall d-flex gap-2 align-items-center dropdown-toggle ps-5'
										to=''
										role='button'
										data-bs-toggle='dropdown'
										aria-expanded='false'
									>
										EN
									</Link>
									<ul className='dropdown-menu dropdown-menu-white'>
										<li>
											<Link className='dropdown-item' to=''>
												EN
											</Link>
										</li>
										<li>
											<Link className='dropdown-item' to=''>
												FR
											</Link>
										</li>
										<li>
											<Link className='dropdown-item' to=''>
												AR
											</Link>
										</li>
									</ul>
								</li>
							</MenuItem>
						</div>
					</Menu>
				</div>
				<div className='searching w-100'>
					<Search mobile={true}/>
				</div>
				<div
					className='icons d-flex gap-3 justify-content-center align-items-center'
					style={{
						fontSize: "25px",
						color: "#fff",
					}}
				>
					<Link to='/cart' className='text-white'>
						<AiOutlineShoppingCart />
					</Link>
					<Link to='/wishlist' className='text-white'>
						<AiOutlineHeart />
					</Link>
				</div>
			</div>
		</>
	)
}
