import React from 'react';
import { Link } from 'react-router-dom';
import CurrencyDropdown from './Dropdowns/NavTop/CurrencyDropdown';
import LanguageDropdown from './Dropdowns/NavTop/LanguageDropdown';

const NavTop = () => {
  return (
    <>
      {/* first navbar */}
      <div
        className=" px-5 container-fluid   py-2 d-flex justify-content-between"
        style={{ backgroundColor: '#000' }}>
        <div className="d-flex align-items-center gap-4">
          <CurrencyDropdown />
          <LanguageDropdown />
        </div>
        <div className="d-flex align-items-center justify-content-end text-white gap-3">
          <a
            className="nav-link ps-5 fsSmall"
            href="https://www.aswaaqsindibad.com/about">
            About Us
          </a>
          <a
            className="nav-link ps-5 fsSmall"
            href="https://www.aswaaqsindibad.com/consultancy">
            Why Sell with Us
          </a>
          <Link className="nav-link ps-5 fsSmall" to="/demand_product">
            Demand Equipment
          </Link>
        </div>
      </div>
    </>
  );
};

export default NavTop;
