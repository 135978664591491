import React from 'react';
import { Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import ItemCard from '../../components/ItemCard/ItemCard';
import WholesaleCard from '../../components/WholesaleCard/WholesaleCard';
import img from '../../assets/Brands/Frame 75.png';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';

const CustomSlider = (props) => {
  const { data, companies, company, home } = props;
  return (
    <div
      className={`${
        home && `col-lg-7`
      } p-0 position-relative m-auto d-flex align-items-center`}>
      {!company && data == undefined ? (
        <div className=" d-flex gap-4 mt-5 justify-content-center">
          {Array(3)
            .fill(0)
            .map(() => (
              <li>
                <Skeleton variant="rectangular" width={230} height={342} />
              </li>
            ))}
        </div>
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          breakpoints={{
            440: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: home ? 3 : 4,
              spaceBetween: 40,
            },
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper px-1 py-3">
          {data?.length > 0 &&
            data?.map((data, index) =>
              data?.item?.length > 0 ? (
                <SwiperSlide key={index} className="py-2">
                  <WholesaleCard data={data} />
                </SwiperSlide>
              ) : (
                <SwiperSlide key={index} className="py-2">
                  <ItemCard data={data} />
                </SwiperSlide>
              )
            )}
        </Swiper>
      )}
      {company && companies == undefined ? (
        <div className=" d-flex gap-4 mt-5 justify-content-center">
          {Array(4)
            .fill(0)
            .map(() => (
              <li>
                <Skeleton variant="rectangular" width={230} height={342} />
              </li>
            ))}
        </div>
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={40}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper my-3">
          {companies
            ?.filter((comp) => comp?.company_name != 'Admin')
            .map((company, index) => (
              <SwiperSlide key={index}>
                <Link
                  className="text-decoration-none text-dark"
                  to={`/supplierstore/${company?.slug}`}>
                  <div className="my-3 px-4 d-flex flex-column justify-content-center align-items-center">
                    <img
                      className="notSelectable"
                      style={{ width: '192px', height: '160px' }}
                      src={
                        company?.business_image ? company?.business_image : img
                      }
                      alt="company"
                    />
                    <p className="fw-bold">{company?.company_name}</p>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </div>
  );
};

export default CustomSlider;
