import {
  Person2Outlined,
  PhoneOutlined,
  EmailOutlined,
  PhotoCamera,
} from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Button } from "bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editUsers } from "../../../redux/reducers/auth";
// import { useState } from "react";

const PersonalInfo = () => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state?.auth?.user?.id);

  const [peronalData, setPersonalData] = useState({});

  const onchange = (e) => {
    if (e.target.name == "phone_number") {
      setPersonalData({
        ...peronalData,
        employee: {
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name == "image") {
      setPersonalData({
        ...peronalData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setPersonalData({
        ...peronalData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onSubmit = () => {
    dispatch(editUsers({ id, peronalData }));
  };

  return (
    <div className="mb-5">
      <p className="personal-title mb-4">Personal Information</p>

      <div className="d-flex justify-content-between align-items-center">
        <IconButton aria-label="upload picture" component="label">
          <input
            hidden
            accept="image/*"
            type="file"
            name="image"
            onChange={onchange}
          />
          <PhotoCamera
            style={{
              fontSize: "200px",
            }}
          />
        </IconButton>
        <div className="d-flex flex-column">
          <div className="d-flex">
            <TextField
              onChange={onchange}
              name="name"
              id="outlined-basic"
              label="Full Name"
              variant="outlined"
              className="w-100"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Person2Outlined />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="d-flex w-100 my-4">
            <TextField
              onChange={onchange}
              name="phone_number"
              id="outlined-basic"
              label="Phone Number"
              variant="outlined"
              className="w-100"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <PhoneOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>

      <div className="d-flex w-100">
        <TextField
          onChange={onchange}
          name="email"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          style={{
            width: "100%",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="w-100">
        <button
          className="btn text-light p-2 mt-3 w-100"
          id="add-address-btn"
          onClick={onSubmit}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default PersonalInfo;
