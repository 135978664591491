import React, { useEffect } from "react";
import { GiDiscGolfBag } from "react-icons/gi";
import img1 from "../../assets/Checkout/visa.png";
import img2 from "../../assets/Checkout/paypal.png";
import img3 from "../../assets/Checkout/cash-on-delivery 1.png";
import Summary from "../Cart/Summary/Summary";
import { useDispatch, useSelector } from "react-redux";
import { getAddresses } from "../../redux/reducers/addresses";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { createOrder, getOrders } from "../../redux/reducers/orders";
import { clearCart } from "../../redux/reducers/cart";
import Banner from "../../components/Banner/Banner";

function Checkout({ page }) {
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addressesList = useSelector((state) => state?.addresses?.addressesList);
  const productsCart = useSelector((state) => state.cart?.productsCart);
  const wholesaleCart = useSelector((state) => state.cart?.wholesaleCart);

  useEffect(() => {
    dispatch(getAddresses());
  }, [dispatch]);

  // const HandleShowDiv = () => {
  //   setShowDiv(!showDiv);
  // }
  const HandleCheckoutOrder = () => {
    if (selectedAddress?.id) {
      console.log(productsCart);
      let copyProductsCart = null;
      if (page == "finance") {
        copyProductsCart = productsCart?.map((product) => {
          return {
            sku: product?.id,
          };
        });
      } else {
        copyProductsCart = productsCart?.map((product) => {
          return {
            sku: product?.sku?.id,
            quantity: product?.quantity,
          };
        });
      }

      console.log(copyProductsCart);

      let copyWholesaleCart = [];
      wholesaleCart?.map((product) => {
        copyWholesaleCart.push(product?.id);
      });
      dispatch(
        createOrder({
          items: copyProductsCart,
          paid_amount: "9000",
          shipping_address: selectedAddress?.id,
          shipping_method: "1",
          whole_sale: copyWholesaleCart,
        })
      ).then((res) => {
        // console.log(res)
        if (res?.meta?.requestStatus == "fulfilled") {
          dispatch(getOrders());
          dispatch(clearCart());
          navigate("/done-order");
        }
      });
    }
  };

  return (
    <div className="container">
      <Banner title="Choose Address" />
      <h1
        className="py-4"
        style={{
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "48px",
          color: "#012A4A",
        }}
      >
        Addresses Available
      </h1>
      <div className="row align-items-start">
        <div className="col-7">
          <div
            className="row justify-content-center align-items-center g-4"
            style={{
              width: "100%",
            }}
          >
            {addressesList?.results?.map((address, index) => {
              return (
                <>
                  <div className="d-flex flex-column">
                    <div
                      style={{
                        backgroundColor: "rgba(42, 111, 152, 0.15)",
                        cursor: "pointer",
                      }}
                      className="w-100 text-center p-3 rounded-2 d-flex justify-content-between align-items-center"
                    >
                      <h3 style={{ color: "var(--darkblue)" }}>
                        {address?.address_name}
                      </h3>
                      <div className="d-flex gap-2 justify-content-center align-items-center">
                        <button
                          className="btn bg-light"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedAddress(address);
                          }}
                        >
                          {selectedAddress?.id == address?.id
                            ? "Selected"
                            : "Select"}
                        </button>
                        {/* {
                  showDiv ? <IoIosArrowUp size={20}/> : <IoIosArrowDown size={20}/>
                } */}
                      </div>
                    </div>

                    <div className="shadow p-3 rounded-2 d-flex flex-column gap-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Country: {address?.country}</h5>
                        <h5>City: {address?.city}</h5>
                      </div>
                      <div>
                        <h5>
                          Address: {address?.street1}, {address?.country},{" "}
                          {address?.city}
                        </h5>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            <h1
              className="py-4"
              style={{
                fontWeight: 600,
                fontSize: "32px",
                lineHeight: "48px",
                color: "#012A4A",
              }}
            >
              Billing Details
            </h1>
            <div className="d-flex justify-content-center align-items-center gap-3 ">
              <div
                className="shadow bg-body rounded d-flex justify-content-center align-items-center"
                style={{
                  width: "192px",
                  height: "121px",
                }}
              >
                <img src={img1} alt="" className="w-60" />
              </div>
              <div
                className="shadow bg-body rounded d-flex justify-content-center align-items-center"
                style={{
                  width: "192px",
                  height: "121px",
                }}
              >
                <img src={img2} alt="" className="w-80" />
              </div>
              <div
                className="shadow bg-body rounded d-flex justify-content-center align-items-center"
                style={{
                  width: "192px",
                  height: "121px",
                }}
              >
                <img src={img3} alt="" className="w-50" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-5 p-0 ps-4">
          <Summary page={page} />
          <div className="d-flex flex-column gap-3">
            {/* <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Enter promo code"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <button
                class="btn"
                id="basic-addon2"
                style={{
                  backgroundColor: "#62A5C2",
                  color: "#fff",
                }}
              >
                Apply
              </button>
            </div> */}

            <Button
              class="btn btn-primary p-3 d-flex justify-content-center align-items-center"
              type="submit"
              style={{
                backgroundColor: "#01497C",
                fontweight: 600,
                fontSize: "16px",
                lineHeight: "24px",
              }}
              onClick={HandleCheckoutOrder}
            >
              Proceed to Order{" "}
              <span className="ms-2">
                <GiDiscGolfBag />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
