import React, { useEffect, useState } from "react";
import img from "../../assets/img/ProductImage1.png";
import { GoHeart } from "react-icons/go";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/reducers/cart";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { IoMdHeartEmpty } from "react-icons/io";

export default function WholesaleCard(props) {
  const data = props;
  const [fav, setFav] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const favHandler = (e) => {setFav(!fav); e.preventDefault()}
  const productsCount = data?.data?.item?.length;
  const item = data?.data;

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart?.wholesaleCart);

  //functions
  const HandleAddToCart = (e) => {
    e.preventDefault();
    toast.success("Added Successfuly", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
    dispatch(
      addToCart({
        id: item?.id,
        title: item?.title,
        stock: item?.item?.length,
        slug: item?.slug,
        price: item?.price,
        company: item?.company,
        items: item?.item,
        type: "wholesale",
      })
    );
  };

  useEffect(() => {
    const itemInCart = cart?.find((cartItem) => cartItem?.id === item?.id);
    itemInCart ? setQuantity(itemInCart?.quantity) : setQuantity(0);
  }, [cart]);
  return (
    <div
      key={props.key1}
      className=" border-0 shadow rounded position-relative bg-white"
      style={{ border: "0.1px solid #D6D6D6", height: "420px", width: "290px" }}
    >
      <div className="position-relative ">
        <div style={{ height: "180px" }}>
          <div
            className="d-flex align-items-start flex-wrap notSelectable "
            style={{
              maxHeight: "180px",
              overflow: "hidden",
            }}
          >
            {data.data.item.map((item) => (
              <Col
                md={6}
                key={props.key1}
                style={{ padding: "2px", height: "fit-content" }}
              >
                <img
                  src={item?.images?.[0]?.image}
                  unselectable="on"
                  className="w-100 p-0 notSelectable"
                  style={{ height: "60px", objectFit: "cover" }}
                  alt="img"
                />
              </Col>
            ))}
          </div>
        </div>
      </div>

      {/* <div className='card-body d-flex flex-column'>
				<Link
					to={`/wholesale/${data?.data?.id}`}
					className='text-decoration-none'
				>
					<p
						className='m-0 fs-4 p-0 fw-bold pt-3 pb-2 notSelectable'
						style={{ color: "var(--black)", lineHeight: "1em" }}
					>
						{data?.data?.title}
					</p>
					<p
						className='m-0 small notSelectable'
						style={{ color: "var(--blue)" }}
					>
						{data?.data?.company}
					</p>
				</Link>

				<Button
					onClick={HandleAddToCart}
					className='btn position-absolute text-decoration-none small rounded-3 px-4 py-2 text-white ms-auto'
					style={{
						backgroundColor: "var(--blue)",
						width: "fit-content",
						bottom: "15px",
						right: "10px",
					}}
					disabled={quantity > 0 ? true : false}
				>
					{quantity > 0 ? "Added Successfuly" : "Add to cart"}
				</Button>
			</div> */}

      <div className="position-relative d-flex p-3">
        <Link
          to={`/product/${item?.slug}`}
          className="card-body text-decoration-none d-flex flex-column gap-2"
		  style={{height:"203px"}}
        >
          <div className="d-flex gap-2 justify-content-between align-items-start">
            <p
              className="m-0 notSelectable fs-5 p-0 fw-bold"
              style={{ color: "var(--black)", lineHeight: "1em" }}
            >
              {item?.title?.slice(0, 30)}
              {item?.title?.length >= 30 && "..."}
            </p>
          
            <button
              className="text-decoration-none m-0 bg-white border-0"
              onClick={favHandler}
             
            >
              {fav ? (
                <GoHeart
                  className="fs-3"
                  style={{
                    color: "var(--purple)",
                  }}
                />
              ) : (
                <IoMdHeartEmpty className="fs-3" />
              )}
            </button>
          </div>


          <p
            className="rounded-1 m-0 notSelectable small py-0 px-2"
            style={{
              width: "fit-content",
              color: data?.data?.item?.length > 0
			  ? "var(--purple)"
			  : "var(--orange)",
              border: `1px solid ${
                data?.data?.item?.length > 0
                    ? "var(--purple)"
                    : "var(--orange)"
              }`,
            }}
          >
			  {data?.data?.item?.length > 0 ? "Wholesale" : "No Tag"}
            
          </p>

          <p
            className="small notSelectable m-0"
            style={{ color: "var(--blue)" }}
          >
            {item?.company?.company_name
              ? item?.company?.company_name
              : "Not Available"}
          </p>

          <button
            onClick={HandleAddToCart}
            className="btn btn-outline-dark text-decoration-none small rounded-1 px-2 py-1 mt-auto"
            style={{
              width: "fit-content",
            }}
            disabled={quantity > 0 ? true : false}
          >
            {quantity > 0 ? "Added Successfuly" : "Add to cart"}
          </button>
        </Link>
      </div>
    </div>
  );
}
