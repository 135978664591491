export const BASE_URL = "https://aswaaq.pythonanywhere.com"
export const tokenConfig = (getState) => {
	// Get token from state
	const token = getState().auth.token

	// Headers
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	}

	// If token, add to headers config
	if (token) {
		config.headers["Authorization"] = `Bearer ${token}`
	}
	return config
}
