import { useEffect } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomSlider from '../../../layouts/CustomSlider/CustomSlider';
import { getProducts } from '../../../redux/reducers/products';

const TopRated = () => {
  const topSection = [
    { title: 'Top Rated' },
    { button: 'View More' },
    { toLink: '/products/top' },
  ];

  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.TPproducts);

  useEffect(() => {
    dispatch(getProducts({ sort: 'top_rated' }));
  }, [dispatch]);
  return (
    <div className="container-fluid px-lg-5 px-3">
      <div className="row gx-0 bg-white HomeBanner d-flex align-items-center justify-content-start">
        <div className=" d-lg-none d-flex topBg bg-white align-items-center justify-content-center gap-3 p-3">
          <h2
            className="text-white text-center m-0"
            style={{
              fontFamily: 'Anton',
              fontSize: '1.5rem',
            }}>
            Top Rated
          </h2>
          <Link
            to="/products/popular"
            className="text-white text-decoration-none m-0"
            style={{
              width: 'fit-content',
            }}>
            View More <MdKeyboardArrowRight />
          </Link>
        </div>

        <CustomSlider home={true} data={products?.results} />
        <div
          style={{ height: '600px' }}
          className="col-lg-4 d-lg-flex d-none rightBanner bg-white p-5 pe-0 flex-column align-items-center justify-content-center gap-5">
          <h2
            className="text-white text-center m-0"
            style={{
              fontFamily: 'Anton',
              fontSize: '3rem',
            }}>
            Top Rated
          </h2>
          <Link
            to="/products/popular"
            className="text-white btn rounded-pill px-4 py-2 m-0"
            style={{
              textShadow: '2px 2px rgba(0, 0, 0,0.3)',
              backgroundColor: 'var(--purple)',
              width: 'fit-content',
            }}>
            View More <MdKeyboardArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopRated;
