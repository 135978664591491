import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFinances } from "../../../../../redux/reducers/finances";
import FinanceCard from "./FinanceCard";
import { ClipLoader } from "react-spinners";

const FinancesList = () => {
  const dispatch = useDispatch();
  const financesList = useSelector(
    (state) => state?.finances?.financeList?.results
  );
  console.log(financesList);
  console.log(financesList);

  useEffect(() => {
    dispatch(getFinances());
  }, []);

  return (
    <>
      {financesList?.map((finance, index) => {
        return <FinanceCard finance={finance} key={index} />;
      })}
      <div
        style={{
          height: 100,
          maxHeight: 500,
        }}
        className="d-flex justify-content-center align-items-center"
      >
        {financesList?.length === 0 && (
          <p className="text-center fw-bold">No Finances Found</p>
        )}
        {!financesList && <ClipLoader height="10" color="rgb(42, 111, 152)" />}
      </div>
    </>
  );
};

export default FinancesList;
