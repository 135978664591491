import ProfilePic from "../../../assets/profile/profile-pic.jpg";
import { MdOutlineEdit, MdLogout } from "react-icons/md";
import CompanyPic from "../../../assets/profile/company.png";
import { useDispatch, useSelector } from "react-redux";
import  { logout } from "../../../redux/reducers/auth";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const ProfileInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state?.auth?.isAuth);

  const user = useSelector((state) => state?.auth?.user);
  const handleUser = () => {
    if (isAuth) {
      dispatch(logout()) && navigate("/login");
    }
  };
  return (
    <>
      <div className=" profile-box d-flex flex-column justify-content-center align-items-center py-5 rounded-3">
        <div
          style={{
            position: "relative",
          }}
        >
          <img
            src={user?.image ? user?.image : ProfilePic}
            width="150"
            alt="user"
          />
          <Link to="/settings" className="rounded-5 edit-box">
            <MdOutlineEdit size={20} color="#fff" />
          </Link>
        </div>

        <h3 className="title">{user?.name ? user?.name : "Not Available"}</h3>
        <div className="w-100 px-3">
          <div className="d-flex justify-content-between align-items-center mx-4">
            <p style={{ color: "#6C757D", fontWeight: "600" }}>Role:</p>
            <p style={{ color: "#2A6F98", fontWeight: "600" }}>
              {user?.employee?.position
                ? user?.employee?.position
                : "Not Available"}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center mx-4">
            <p style={{ color: "#6C757D", fontWeight: "600" }}>Phone:</p>
            <p style={{ color: "#2A6F98", fontWeight: "600" }}>
              {user?.employee?.phone_number
                ? user?.employee?.phone_number
                : "Not Available"}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center mx-4">
            <p style={{ color: "#6C757D", fontWeight: "600" }}>Email:</p>
            <p style={{ color: "#2A6F98", fontWeight: "600" }}>
              {user?.email ? user?.email : "Not Available"}
            </p>
          </div>
        </div>
        <div className="company-box d-flex align-items-center gap-3 p-2 m-2 rounded-3 pe-5 ps-3">
          <img
            className="rounded-circle"
            src={
              user?.employee?.company?.business_image
                ? `https://surpluscity.pythonanywhere.com${user?.employee?.company?.business_image}`
                : CompanyPic
            }
            width="75"
            alt="company"
          />
          <div className="mt-3">
            <h5
              className="p-0 m-0 text-light"
              style={{
                fontSize: 15,
              }}
            >
              Company Name
            </h5>
            <p
              className=" text-light"
              style={{
                fontSize: 13,
              }}
            >
              {user?.employee?.company?.company_name
                ? user?.employee?.company?.company_name
                : "Not Available"}
            </p>
          </div>
        </div>
      </div>

      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <button
          id="logout-btn"
          onClick={handleUser}
          className="btn  mt-3 text-light py-2 mt-4 d-flex gap-2 justify-content-center align-items-center"
          style={{
            width: "70%",
          }}
        >
          Log Out <MdLogout />{" "}
        </button>
      </div>
    </>
  );
};

export default ProfileInfo;
