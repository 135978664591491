import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../common";

const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

export const getCompaniesSearch = createAsyncThunk(
  "companiesSearch/getCompany",
  async (input = "", { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/company/mp/search/?find=${input}`
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCompanies = createAsyncThunk(
  "companies/getCompanies",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/company/mp/companies/`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getCompany = createAsyncThunk(
  "companies/getCompany",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/company/mp/companies/slug/${slug}/`
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getCompanyPages = createAsyncThunk(
  "companies/getCompanyPages",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/company/cms/${id}/pages/`, {
        headers: {
          ["Authorization"]: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const contactUs = createAsyncThunk(
  "company/contactus",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const response = await axios.post(
        `${BASE_URL}/company/contact-us/`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  company: null,
  companyDetails: null,
  companyPages: null,
  allCompanies: null,
  data: null,
  token: token,
  error: false,
  isAuth: false,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    // 	LOAD
    [getCompaniesSearch.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.isAuth = false;
    },
    [getCompaniesSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.company = action.payload;
      state.isAuth = true;
      state.error = false;
    },
    [getCompaniesSearch.rejected]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.error = action.payload;
    },

    [getCompanies.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.isAuth = false;
    },
    [getCompanies.fulfilled]: (state, action) => {
      state.loading = false;
      state.allCompanies = action.payload;
      state.isAuth = true;
      state.error = false;
    },
    [getCompanies.rejected]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.error = action.payload;
    },
    [getCompany.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.isAuth = false;
    },
    [getCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.companyDetails = action.payload;
      state.isAuth = true;
      state.error = false;
    },
    [getCompany.rejected]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.error = action.payload;
    },
    [getCompanyPages.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.isAuth = false;
    },
    [getCompanyPages.fulfilled]: (state, action) => {
      state.loading = false;
      state.companyPages = action.payload;
      state.isAuth = true;
      state.error = false;
    },
    [getCompanyPages.rejected]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.error = action.payload;
    },
  },
});

export default authSlice.reducer;
