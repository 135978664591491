import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import SupplierBanner from "../../assets/common/supplierBanner.png";
import { getCompany } from "../../redux/reducers/companies";
import SupplierInfo from "./SupplierInfo/SupplierInfo";
import "./SupplierStore.css";
import SupplierStoreTaps from "./SupplierStoreTaps/SupplierStoreTaps";

const SupplierStore = () => {
window.scrollTo(0, 0);

  const params = useParams();
  const company = useSelector((state) => state?.companies?.companyDetails);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCompany(params?.slug))
  }, [])
  
  return (
    <div className="container mt-3">
      <img
        src={SupplierBanner}
        alt="supplier-store"
        style={{ width: "100%" }} 
      />
        <SupplierInfo company={company} />
        <SupplierStoreTaps company={company} />
    </div>
  );
};

export default SupplierStore;