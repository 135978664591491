import React, { useState } from "react"
import img1 from "../../../assets/auth/Category Banner.png"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { Checkbox, FormGroup } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { postLogin } from "../../../redux/reducers/auth"
import ClipLoader from "react-spinners/ClipLoader"
import Joi from "joi"

const Login = () => {
	// usehooks
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const loading = useSelector((state) => state.auth.loading)

	// useState

	const [login, setLogin] = useState({
		email: "",
		password: "",
	})
	const [err, setErr] = useState([])
	const [errBack, setErrBack] = useState([])

	// Catch errors to display next to input field

	function getCurrentErr(key) {
		for (const e of err) {
			if (e.context.key === key) {
				return e.message
			}
		}
		return ""
	}

	// Store data in the usestate

	const onchange = (e) => {
		setLogin({ ...login, [e.target.name]: e.target.value })

		//filter the current error only

		let filteredErrors = []
		filteredErrors = err?.filter((k) => k?.path[0] !== e.target.name)
		setErr(filteredErrors)
		setErrBack("")
	}

	// submit to done or get errors

	const onSubmit = (e) => {
		e.preventDefault()
		let schema = Joi.object({
			email: Joi.string()
				.email({ minDomainSegments: 2, tlds: ["com", "net"] })
				.required()
				.label("Email"),
			// .pattern(/^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo)).*\.[a-zA-Z]{2,6}$/)
			// .rule({ message: "please enter your Business account" })
			password: Joi.string().required().label("Password"),
		})

		let joiResponse = schema.validate(login, { abortEarly: false })

		if (joiResponse.error) {
			setErr(joiResponse.error.details)
		} else {
			try {
				dispatch(postLogin({ ...login })).then((res) => {
					if (res.meta.requestStatus !== "rejected") {
						navigate("/")
					} else {
						// setErr(res.payload.detail);
						setErrBack(res.payload.detail)
					}
				})
			} catch (error) {}
		}
	}

	return (
		<div className='container-fluid p-0 m-0'>
			<div className='row justify-content-center p-0 m-0'>
				<div className='col-lg-6 d-lg-block d-none p-0 m-0'>
					<div className='position-relative h-100'>
						<img
							className='w-100 position-relative'
							src={img1}
							alt='img1'
							style={{
								objectFit: "cover",
								height: "100vh",
							}}
						/>
						<h1
							className='position-absolute font_A'
							style={{
								maxWidth: "176px",
								width: "100%",
								height: "95px",
								left: "54.42px",
								top: "36px",
								fontWeight: 400,
								fontSize: "47.5px",
								lineHeight: "95px",
								color: "#FFFFFF",
								letterSpacing: "5.2px",
							}}
						>
							Surplus
						</h1>
					</div>
				</div>
				<div
					className='col-lg-6  d-flex flex-column justify-content-center align-items-center'
					style={{ minHeight: "100vh" }}
				>
					<h1
						className='font'
						style={{
							fontWeight: 600,
							fontSize: "48px",
							lineHeight: "72px",
							color: "rgba(1, 73, 124, 1)",
						}}
					>
						Sign In
					</h1>
					<p
						className='text-center'
						style={{
							fontWeight: 500,
							fontSize: "24px",
							lineHeight: "38px",
							color: "rgba(1, 73, 124, 1)",
						}}
					>
						<span className='text-danger'>Welcome back!</span> Please enter your
						details
					</p>
					<div>
						<Box
							component='form'
							sx={{
								"& .MuiTextField-root": { m: 1, width: "25ch" },
							}}
							noValidate
							autoComplete='off'
						>
							<div className='d-flex flex-column justify-content-center align-items-center'>
								<TextField
									className='mb-4 mt-4'
									error={
										getCurrentErr("email") || errBack.length !== 0
											? true
											: false
									}
									style={{
										maxWidth: "438px",
										width: "100%",
										borderRadius: "5px",
									}}
									required
									id='outlined-required'
									label={
										getCurrentErr("email")
											? getCurrentErr("email") && "You must write a valid email"
											: errBack.length === 0
											? "Email"
											: errBack
									}
									name='email'
									defaultValue=''
									onChange={onchange}
								/>
								<TextField
									error={getCurrentErr("password") ? true : false}
									style={{
										maxWidth: "438px",
										width: "100%",
										borderRadius: "5px",
									}}
									required
									id='outlined-password-input'
									label={
										getCurrentErr("password")
											? getCurrentErr("password") &&
											  "You must write a valid password"
											: "Password"
									}
									type='password'
									name='password'
									onChange={onchange}
								/>
								<div
									className='d-flex justify-content-between align-items-center'
									style={{
										maxWidth: "438px",
										width: "100%",
										color: "rgba(70, 143, 175, 1)",
									}}
								>
									<div className='d-flex align-items-center justify-content-center'>
										<div>
											<Checkbox size='large' />
										</div>
										<div
											style={{
												fontWeight: 600,
												fontSize: "16.31px",
												lineHeight: "19.57px",
												color: "rgba(70, 143, 175, 1)",
											}}
										>
											Remember Me
										</div>
									</div>
									<FormGroup
										className='py-4'
										style={{
											fontWeight: 600,
											fontSize: "16.31px",
											lineHeight: "19.57px",
											color: "rgba(70, 143, 175, 1)",
										}}
									>
										<Link
											to='/forgetpassword'
											style={{
												textDecoration: "none",
											}}
										>
											Forget your Password?
										</Link>
									</FormGroup>
								</div>
								<button
									className='d-flex justify-content-center align-items-center'
									type='submit'
									onClick={onSubmit}
									style={{
										maxWidth: "438px",
										width: "100%",
										background: "rgba(1, 73, 124, 1)",
										color: "#fff",
										height: "60px",
										padding: "0px 20.9279px",
										fontWeight: 700,
										fontSize: "20.9279px",
										lineHeight: "120%",
										borderRadius: "10px",
										border: "none",
									}}
								>
									{loading ? (
										<ClipLoader height='10' color='#36d7b7' />
									) : (
										"Sign in"
									)}
								</button>
								<p
									className='text-muted mt-4'
									style={{
										color: "#959DA9",
										textDecoration: "none",
										fontWeight: 400,
										fontSize: "20.9279px",
										lineHeight: "120%",
									}}
								>
									Don’t have an account?
									<span className='ms-2'>
										<Link
											to='/registration'
											style={{
												color: "rgba(98, 165, 194, 1)",
												textDecoration: "none",
												fontWeight: 600,
												fontSize: "20.9279px",
												lineHeight: "120%",
											}}
										>
											Sign Up
										</Link>
									</span>
								</p>
							</div>
						</Box>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
