import { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { getProducts } from "../../redux/reducers/products"
import { STYLING } from "../Orders/OrdersCard/styling"

const Dropdown = (props) => {
	let { placeholder, choices, size, sort, full, setSelectedProduct, setSort } =
		props
	const [selected, setSelected] = useState(placeholder)
	const dispatch = useDispatch()

	const [data, setData] = useState([])
	const [sorting, setSorting] = useState([])
	const small = size === "sm"
	const sortArray = [
		"By Date: Newest",
		"By Date: Oldest",
		"By Price: Higher",
		"By Price: Lower",
	]

	useEffect(() => {
		sort ? setData(sortArray) : setData(choices)
		// dispatch(getProducts({ sort: sorting }));
	}, [sort, sorting, choices])

	const onchange = (e) => {
		if (e.target.innerText.includes("Newest")) {
			// props?.dataa("newest");
			setSort("newest")
		}
		if (e.target.innerText.includes("Oldest")) {
			// props?.dataa("oldest");
			setSort("oldest")
		}
		if (e.target.innerText.includes("Higher")) {
			// props?.dataa("highest price");
			setSort("highest_price")
		}
		if (e.target.innerText.includes("Lower")) {
			// props?.dataa("lowest price");
			setSort("lowest_price")
		}
		setSelected(e.target.innerText)
		setSelectedProduct(e.target.innerText)
	}

	return (
		<div
			className='dropdown'
			style={{
				maxWidth: full ? "100%" : "300px",
				width: full ? "100%" : "fit-content",
				borderBottom:"1px solid #ededed"

			}}
		>
			<button
				className='border-0 px-4 py-md-3 py-2 btn-white rounded-3 w-100 dropdown-toggle d-flex align-items-center justify-content-between'
				style={{
					fontFamily: "Montserrat",
					color: "#666666",
					backgroundColor: "#fff",
					fontSize: small && STYLING.font.size.small,
				}}
				type='button'
				id='dropdownMenuButton1'
				data-bs-toggle='dropdown'
				aria-expanded='false'
			>
				{selected}
			</button>
			<ul className='dropdown-menu w-100' aria-labelledby='dropdownMenuButton1'>
				{data?.map((choice, index) => (
					<li key={index}>
						<p className='dropdown-item' onClick={onchange}>
							{choice}
						</p>
					</li>
				))}
			</ul>
		</div>
	)
}

export default Dropdown
