import React, { useEffect, useState } from 'react';
import img from '../../assets/img/ProductImage1.png';
import { BsStarFill } from 'react-icons/bs';
import { GoHeart } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  decreaseQuantity,
  increaseQuantity,
} from '../../redux/reducers/cart';
import { toast } from 'react-toastify';
import { IoMdHeartEmpty } from 'react-icons/io';
export default function ItemCard(props) {
  const data = props;
  // console.log(data)
  const item = data?.data?.sku_product ? data?.data?.sku_product : data?.data;
  // console.log("itemm",item)
  const starArray = [...Array(5).keys()].map((i) => i + 1);
  const [fav, setFav] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const favHandler = (e) => {
    setFav(!fav);
    e.preventDefault();
  };

  const sku = data?.data?.skus?.[0];
  const unit = data?.data?.unit === 'unit' ? '' : `per ${data?.data?.unit}`;

  //dispatch
  const dispatch = useDispatch();
  //selector
  const cart = useSelector((state) => state?.cart?.productsCart);

  //functions
  const HandleAddToCart = (e) => {
    e.preventDefault();
    toast.success('Added Successfuly', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'light',
    });
    dispatch(
      addToCart({
        id: item?.id,
        title: item?.title,
        stock: item?.skus?.[0]?.stock,
        slug: item?.slug,
        price: item?.price,
        unit: item?.unit,
        company: item?.company,
        gallery: data?.data?.images,
        tag: item?.tag,
        sku: item?.skus?.[0],
        currency: item?.price_currency,
        type: 'products',
      })
    );
  };
  console.log('itemcard', data?.data?.images);
  const HandleIncreaseQuantity = (e) => {
    e.preventDefault();
    dispatch(increaseQuantity({ id: item?.id, stock: item?.skus?.[0]?.stock }));
  };
  const HandleDecreaseQuantity = (e) => {
    e.preventDefault();
    dispatch(decreaseQuantity({ id: item?.id }));
  };

  useEffect(() => {
    const itemInCart = cart?.find((cartItem) => cartItem?.id === item?.id);
    itemInCart ? setQuantity(itemInCart?.quantity) : setQuantity(0);
  }, [cart]);

  return (
    <div
      className="shadow border-0 rounded position-relative bg-white"
      style={{
        border: '0.1px solid #D6D6D6',
        height: '420px',
        width: '230px',
      }}>
      <div className="position-relative">
        <img
          src={
            data?.data?.images?.[0]?.image
              ? data?.data?.images?.[0]?.image
              : item?.csv
              ? item?.images?.[0]?.image
                ? item?.images?.[0]?.image
                : item?.gallery?.[0]?.image
                ? item?.gallery?.[0]?.image
                : img
              : img
          }
          unselectable="on"
          className="w-100 notSelectable"
          alt="product"
          style={{
            height: '180px',
            objectFit: 'cover',
          }}
        />
      </div>

      <div className="position-relative d-flex p-3">
        <Link
          to={`/product/${item?.slug}`}
          className="card-body text-decoration-none d-flex flex-column gap-2">
          <div className="d-flex gap-2 justify-content-between align-items-start">
            <p
              className="m-0 notSelectable fs-6 p-0 fw-bold"
              style={{ color: 'var(--black)', lineHeight: '1em' }}>
              {item?.title?.slice(0, 30)}
              {item?.title?.length >= 30 && '...'}
            </p>

            <button
              className="text-decoration-none m-0 bg-white border-0"
              onClick={favHandler}
              style={{ cursor: 'pointer', top: '15px', right: '10px' }}>
              {fav ? (
                <GoHeart
                  className="fs-3"
                  style={{
                    color: 'var(--purple)',
                  }}
                />
              ) : (
                <IoMdHeartEmpty className="fs-3" />
              )}
            </button>
          </div>
          <p
            className="rounded-1 m-0 notSelectable small py-0 px-2"
            style={{
              width: 'fit-content',
              color: data?.data?.tag?.[0]?.color
                ? data?.data?.tag?.[0]?.color
                : item?.tag?.[0]?.color,
              border: `1px solid ${
                data?.data?.tag?.[0]?.color
                  ? data?.data?.tag?.[0]?.color
                  : item?.tag?.[0]?.color
                  ? item?.tag?.[0]?.color
                  : '#000'
              }`,
            }}>
            {data?.data?.tag?.[0]?.title
              ? data?.data?.tag?.[0]?.title
              : item?.tag?.[0]?.title
              ? item?.tag?.[0]?.title
              : 'No Tag'}
          </p>

          <p
            className="small notSelectable m-0"
            style={{ color: 'var(--blue)' }}>
            {item?.company?.company_name
              ? item?.company?.company_name
              : 'Not Available'}
          </p>
          {!data?.data?.sku_product && (
            <span className="m-0">
              {starArray.map((i, index) => (
                <BsStarFill
                  key={index}
                  className="me-1"
                  style={{
                    color: item?.rating_avg >= i ? '#FFD700' : '#D0D5DB',
                  }}
                />
              ))}
            </span>
          )}
          {!data?.data?.sku_product && (
            <p className="m-0 small notSelectable">
              {sku?.price_currency} {Number(sku?.price)} {unit}
            </p>
          )}
          {quantity === 0 ? (
            !data?.data?.sku_product && (
              <button
                onClick={(e) => HandleAddToCart(e)}
                className="btn btn-outline-dark text-decoration-none small rounded-1 px-2 py-1"
                style={{
                  width: 'fit-content',
                }}>
                Add to cart
              </button>
            )
          ) : (
            <>
              <div
                className="d-flex justify-content-center align-items-center gap-3 ms-auto"
                onClick={(e) => e.preventDefault()}>
                <Button
                  className="btn text-decoration-none"
                  style={{
                    backgroundColor: 'var(--blue)',
                  }}
                  onClick={(e) => HandleDecreaseQuantity(e)}>
                  -
                </Button>
                <p className="text-center position-relative p-0 m-0">
                  {quantity}
                </p>
                <Button
                  className="btn text-decoration-none"
                  style={{
                    backgroundColor: 'var(--blue)',
                  }}
                  onClick={(e) => HandleIncreaseQuantity(e)}>
                  +
                </Button>
              </div>
            </>
          )}
        </Link>
      </div>
    </div>
  );
}
