import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { STYLING } from "./styling"
import ProductImage1 from "../../../assets/img/ProductImage1.png"

const OrderItem = (props) => {
	const { item, variation } = props
	const VAR1 = variation === 1
	const statusStyle =
		item?.status === "Approved"
			? "success"
			: item?.status === "Pending"
			? "warning"
			: "danger"
	const style = {
		img: !VAR1
			? STYLING.readyMadeStyling.columnImg
			: STYLING.readyMadeStyling.columnImgV1,
		title: !VAR1
			? STYLING.readyMadeStyling.columnTitle
			: STYLING.readyMadeStyling.columnTitleV1,
		subtitle: !VAR1
			? STYLING.readyMadeStyling.columnSubtitle
			: STYLING.readyMadeStyling.columnSubtitleV1,
		status: !VAR1
			? STYLING.readyMadeStyling.columnStatus
			: STYLING.readyMadeStyling.columnStatusV1,
	}
	return (
		<div className='py-3 mb-3 d-flex gap-4 border-bottom order w-100'>
			<div>
				<img
					className='rounded'
					src={item.image ? item?.image : ProductImage1}
					style={style.img}
				/>
			</div>
			<div
				className='d-flex col-3 flex-column justify-content-center '
				style={{ width: "fit-content" }}
			>
				<p className='mb-1' style={style.title}>
					{item?.title
						? item?.title
						: item?.product_details?.title?.slice(0, 20)}
				</p>
				<p className='mb-0' style={style.subtitle}>
					{item?.title ? "Wholesale" : "product"} Name
				</p>
			</div>
			<div className='d-flex col-3 flex-column justify-content-center '>
				<p className='mb-1' style={style.title}>
					{item?.item?.[0]?.company_name
						? item?.item?.[0]?.company_name
						: item?.company_name}
				</p>

				<p className='mb-0' style={style.subtitle}>
					Company{" "}
				</p>
			</div>
			<div className='d-flex col flex-column justify-content-center '>
				<p className='mb-1' style={style.title}>
					{item?.quantity ? item?.quantity : 1}
				</p>

				<p className='mb-0' style={style.subtitle}>
					Quantity
				</p>
			</div>
			<div className='d-flex col flex-column justify-content-center '>
				<p className='mb-1' style={style.title}>
					{item?.total ? item?.total : item?.price}
				</p>

				<p className='mb-0' style={style.subtitle}>
					Price
				</p>
			</div>
			<div className='d-flex flex-column justify-content-center align-items-end '>
				<Button
					variant={`outline-${statusStyle}`}
					className=' mb-1'
					disabled
					style={{
						color: item?.status?.color
							? item?.status?.color
							: item?.status?.color,
						border: `1px solid ${item?.status?.color}`,
					}}
				>
					{item?.status?.[0]?.status?.toUpperCase()
						? item?.status?.[0]?.status?.toUpperCase()
						: item?.status?.status?.toUpperCase()
						? item?.status?.status?.toUpperCase()
						: "Not Available"}
				</Button>
			</div>
		</div>
	)
}

export default OrderItem
