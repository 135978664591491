import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getbrands } from '../../../redux/reducers/brands';
import img from '../../../assets/Brands/Frame 75.png';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
export default function Brands() {
  const brands = useSelector((state) => state?.brands?.brands?.results);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getbrands());
  }, [dispatch]);
  return (
    <div className="container-fluid  px-lg-5 px-3 mb-5">
      <div className="bg-white p-5 d-flex flex-column justify-content-center align-items-center">
        <h2 className="fw-bold fs-2 mb-5 text-center">Brands</h2>
        <div
          className="d-flex flex-lg-row flex-column align-items-center px-5 pb-5 w-100"
          style={{ overflow: 'auto' }}>
          {brands?.slice(0, 5)?.map((brand) => (
            <div
              className="p-5   border d-flex flex-column gap-2 justify-content-center align-items-center"
              style={{ flexGrow: '1', height: '250px' }}>
              <img
                style={{ width: '75px' }}
                src={brand?.image ? brand?.image : img}
                alt={brand?.title}
              />
              {/* {brand?.title} */}
            </div>
          ))}
        </div>
        <Link
          to="/brands"
          className="text-white btn rounded-pill px-4 py-2 m-0"
          style={{
            textShadow: '2px 2px rgba(0, 0, 0,0.3)',
            backgroundColor: 'var(--purple)',
            width: 'fit-content',
          }}>
          View More <MdKeyboardArrowRight />
        </Link>
      </div>
    </div>
  );
}
