import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import Login from "../components/Auth/Login/Login"
import DetailsTemplate from "../layouts/DetailsTemplate/DetailsTemplate"
import Footer from "../layouts/Footer/Footer"
import Header from "../layouts/Header/Header"
import Page404 from "../layouts/Page404/Page404"
import Finance from "../pages/Finance/Finance"
import Home from "../pages/Home/Home"
import OrdersPage from "../pages/OrdersPage/OrdersPage"
import OrderPage from "../pages/OrdersPage/OrderPage"
import ProductPage from "../pages/ProductPage/ProductPage"
import RequestProduct from "../pages/RequestProduct/RequestProduct"
import Settings from "../pages/Settings/Settings"
import Profile from "../pages/Profile/Profile"
import Cart from "../pages/Cart/Cart"
import Checkout from "../pages/Checkout/Checkout"
import DoneOrder from "../pages/DoneOrder/DoneOrder"
import Brands from "../pages/brands/brands"
import Wholesale from "../pages/WholeSale/Wholesale"
import SupplierStore from "../pages/SupplierStore/SupplierStore"
import Registraion from "../components/Auth/Registration/Registraion"
import ResetPassword from "../components/Auth/ResetPassword/ResetPassword"
import ForgetPassword from "../components/Auth/ForgetPassword/ForgetPassword"
import { useDispatch, useSelector } from "react-redux"
import { search } from "../redux/reducers/products"
import Categories from "../pages/Categories/Categories"
import ChangePassword from "../components/Auth/ChangePassword/ChangePassword"
import { getLoadUser } from "../redux/reducers/auth"
import PrivateRoutes from "../routes/PrivateRoutes"
import { ToastContainer } from "react-toastify"

const MarketplaceApp = () => {
	const token = useSelector((state) => state.auth.token)
	const location = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(search(null))
	}, [])
	useEffect(() => {
		token && dispatch(getLoadUser(null))
	}, [token])
	return (
		<>
			<Container fluid className='p-0'>
				{location.pathname !== "/login" &&
					location.pathname !== "/registration" &&
					location.pathname !== "/resetpassword" &&
					location.pathname !== "/forgetpassword" &&
					location.pathname !== "/changepassword" &&
					!location.pathname.includes("/auth/users/reset_password/Nw/") && (
						<Header />
					)}
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='*' element={<Page404 />} />

					<Route element={<PrivateRoutes />}>
						<Route path='/profile' element={<Profile />} />
						<Route path='/changepassword' element={<ChangePassword />} />
						<Route path='/settings' element={<Settings />} />
						<Route path='/checkout' element={<Checkout />} />
						<Route path='/finance/checkout' element={<Checkout page={'finance'}/>} />
						<Route path='/done-order' element={<DoneOrder />} />
					</Route>

					<Route path='/login' element={<Login />} />
					<Route path='/registration' element={<Registraion />} />
					<Route
						path='/auth/users/reset_password/:uid/:token'
						element={<ResetPassword />}
					/>
					<Route path='/forgetpassword' element={<ForgetPassword />} />
					<Route path='/orders' element={<OrdersPage />} />
					<Route path='/orders/order' element={<OrderPage />} />
					<Route path='/orders/order/:id' element={<OrderPage />} />
					<Route
						path='/search/:search'
						element={<DetailsTemplate search={true} />}
					/>
					<Route path='/demand_product' element={<RequestProduct />} />
					<Route path='/finance' element={<Finance />} />
					<Route path='/product/:slug' element={<ProductPage />} />
					<Route
						path='/product/wholesale/:slug'
						element={<ProductPage wholesale={true} />}
					/>
					<Route path='/categories' element={<Categories />} />

					<Route path='/cart' element={<Cart />} />
					<Route path='/brands' element={<Brands />} />
					<Route
						path='/brand/:brand'
						element={<DetailsTemplate pageName='brand' sort='brand' />}
					/>

					<Route path='/wholesale/:id' element={<Wholesale />} />
					<Route path='/supplierstore/:slug' element={<SupplierStore />} />
					<Route
						path='/category/:category'
						element={<DetailsTemplate type='category' />}
					/>
					<Route
						path='/sub_category/:category'
						element={<DetailsTemplate type='sub_category' />}
					/>
					<Route
						path='/products/reduced'
						element={<DetailsTemplate pageName='Reduced' />}
					/>
					<Route
						path='/products/popular'
						element={<DetailsTemplate pageName='Most Popular' sort='popular' />}
					/>
					<Route
						path='/products/'
						element={<DetailsTemplate pageName='New Arrivals' sort='newest' />}
					/>
					<Route
						path='/products/top'
						element={<DetailsTemplate pageName='Top Rated' sort='top_rated' />}
					/>
					<Route
						path='/products/new_equipment'
						element={<DetailsTemplate pageName='New Equipment' tag='new' />}
					/>
					<Route
						path='/products/used_equipment'
						element={<DetailsTemplate pageName='Used Equipment' tag='used' />}
					/>
					<Route
						path='/products/reduced_equipment'
						element={
							<DetailsTemplate pageName='Reduced Equipment' tag='reduced' />
						}
					/>
					<Route
						path='/products/auctioned_equipment'
						element={
							<DetailsTemplate pageName='Auctioned Equipment' tag='auction' />
						}
					/>
				</Routes>
				{location.pathname !== "/login" &&
					location.pathname !== "/registration" &&
					location.pathname !== "/resetpassword" &&
					location.pathname !== "/forgetpassword" &&
					location.pathname !== "/changepassword" &&
					!location.pathname.includes("/auth/users/reset_password/") && (
						<Footer />
					)}
			</Container>
		</>
	)
}

export default MarketplaceApp
