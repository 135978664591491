import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Filter from '../../../../components/Filter/Filter';
import ItemCard from '../../../../components/ItemCard/ItemCard';
import WholesaleCard from '../../../../components/WholesaleCard/WholesaleCard';

const SupplierHome = (props) => {
  const { companyProducts } = props;
  const location = useLocation();
  const params = useParams();

  console.log(companyProducts);

  const category_page = location?.pathname?.includes('/category/');
  const sub_category_page = location?.pathname?.includes('/sub_category/');

  const [filter, setFilter] = useState({
    submit: false,
    price: [0, 10000],
    category: [],
  });
  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <p className="m-0 fs-4 fw-bold" style={{ color: 'var(--blue)' }}>
          Most recent Products
        </p>
        <Link
          to=""
          className="btn py-1 px-4 rounded-pill text-white"
          style={{ backgroundColor: 'var(--purple)' }}>
          View All
        </Link>
      </div>
      <Row>
        <Col
          lg={9}
          className="d-flex gap-3 justify-content-md-start justify-content-center flex-wrap  my-3"
          style={{ height: 'fit-content' }}>
          {!companyProducts?.results ? (
            <div className=" d-flex gap-4 justify-content-center flex-wrap">
              {Array(6)
                .fill(0)
                .map(() => (
                  <li>
                    <Skeleton variant="rectangular" width={230} height={342} />
                  </li>
                ))}
            </div>
          ) : (
            companyProducts?.results?.map((item) =>
              item?.item?.length > 0 ? (
                <WholesaleCard data={item} />
              ) : (
                <ItemCard data={item} />
              )
            )
          )}
        </Col>
        <div className="col-lg-3 my-3 p-0 d-flex flex-column gap-4 justify-content-start">
          <Dropdown placeholder="Sort By" sort={true} />
          <Filter
            page={{
              category: { active: category_page, value: params.category },
              sub_category: sub_category_page,
            }}
            setFilter={setFilter}
            filter={filter}
          />
        </div>
      </Row>
    </div>
  );
};

export default SupplierHome;
