import React, { useEffect, useState } from 'react';
import img from '../../assets/img/Rectangle 81.png';
import img2 from '../../assets/img/OrderPageTopBanner.png';
import { IoIosArrowDropright } from 'react-icons/io';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
// import required modules
import { Pagination, Autoplay, EffectFade } from 'swiper';
import CategoryCards from '../../components/CategoryCards/CategoryCards';
import { useDispatch, useSelector } from 'react-redux';
import MostPopular from './MostPopular/MostPopular';
import NewArrivals from './NewArrivals/NewArrivals';
import TopRated from './TopRated/TopRated';
import TopCompanies from './TopCompanies/TopCompanies';
import { getCategories } from '../../redux/reducers/categories';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { MdKeyboardArrowRight } from 'react-icons/md';
import ItemCard from '../../components/ItemCard/ItemCard';
import { getProducts } from '../../redux/reducers/products';
import WholesaleCard from '../../components/WholesaleCard/WholesaleCard';
import Brands from './Brands/Brands';

export default function Home(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(null);

  //redux
  const dispatch = useDispatch();
  const categoriesList = useSelector((state) => state.categories.categories);
  const loading = useSelector((state) => state.categories.loading);
  const categories = categoriesList?.results;
  const products = useSelector((state) => state?.products?.MPproducts);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    dispatch(getProducts({ sort: 'popular' }));
  }, [dispatch]);

  useEffect(() => {
    setCurrentCategory(categories?.[0]);
  }, [categories]);

  console.log('cat', products);
  return (
    <>
      <Swiper
        loop={true}
        modules={[Pagination, Autoplay, EffectFade]}
        effect="fade"
        speed={1200}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          el: '.my-custom-pagination-div',
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        }}
        className="mySwiper hero-section position-relative">
        <SwiperSlide className="position-relative">
          <img
            src="https://static.wixstatic.com/media/c837a6_837f9cd4f59146c3ad47a2bd882fedfd~mv2.png/v1/fill/w_1920,h_565,al_r,q_90,enc_auto/c837a6_837f9cd4f59146c3ad47a2bd882fedfd~mv2.png"
            className="w-100 h-100 slidImg"
            alt="home"
          />
          <div
            className="d-flex position-absolute w-100 flex-column"
            style={{
              top: '50%',
              transform: 'translateY(-50%)',
              left: '10%',
              zIndex: '3',
            }}>
            <p
              className="m-0 mb-3 px-3 py-1 text-white bg-danger"
              style={{ width: 'fit-content' }}>
              Best Prices
            </p>
            <h2
              className="text-dark homeText m-0 fw-bold"
              style={{
                letterSpacing: '6px',
              }}>
              Incredible Prices <br /> on All Your <br /> Favorite Items
            </h2>
            <p className="text-dark my-3">
              Get more for less on selected brands
            </p>
            <Link
              className="btn rounded-pill px-5 py-2 mt-3  text-white"
              style={{
                backgroundColor: 'var(--purple)',
                width: 'fit-content',
              }}>
              Shop Now
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide className="position-relative">
          <img
            src="https://static.wixstatic.com/media/c837a6_f58829a26e594ca3aa72383e19cf39b9~mv2.png/v1/fill/w_1920,h_565,al_r,q_90,enc_auto/c837a6_f58829a26e594ca3aa72383e19cf39b9~mv2.png"
            className="w-100 h-100 slidImg"
            alt="home"
          />
          <div
            className="d-flex position-absolute w-100 flex-column"
            style={{
              top: '50%',
              transform: 'translateY(-50%)',
              left: '10%',
              zIndex: '3',
            }}>
            <p
              className="m-0 mb-3 px-3 py-1 text-white bg-danger"
              style={{ width: 'fit-content' }}>
              Best Prices
            </p>
            <h2
              className="text-dark homeText m-0 fw-bold"
              style={{
                letterSpacing: '6px',
              }}>
              Incredible Prices <br /> on All Your <br /> Favorite Items
            </h2>
            <p className="text-dark my-3">
              Get more for less on selected brands
            </p>
            <Link
              className="btn rounded-pill px-5 py-2 mt-3 text-white"
              style={{
                backgroundColor: 'var(--purple)',
                width: 'fit-content',
              }}>
              Shop Now
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide className="position-relative">
          <img
            src="https://static.wixstatic.com/media/c837a6_9c1280daaeb0481abc58e6e236efdf59~mv2.png/v1/fill/w_1920,h_565,al_br,q_90,enc_auto/c837a6_9c1280daaeb0481abc58e6e236efdf59~mv2.png"
            className="w-100 h-100 slidImg"
            alt="home"
          />
          <div
            className="d-flex position-absolute w-100 flex-column"
            style={{
              top: '50%',
              transform: 'translateY(-50%)',
              left: '10%',
              zIndex: '3',
            }}>
            <p
              className="m-0 mb-3 px-3 py-1 text-white bg-danger"
              style={{ width: 'fit-content' }}>
              Best Prices
            </p>
            <h2
              className="text-dark homeText m-0 fw-bold"
              style={{
                letterSpacing: '6px',
              }}>
              Incredible Prices <br /> on All Your <br /> Favorite Items
            </h2>
            <p className="text-dark my-3">
              Get more for less on selected brands
            </p>
            <Link
              to="/category/perfumes"
              className="btn rounded-pill px-5 py-2 mt-3 text-white"
              style={{
                backgroundColor: 'var(--purple)',
                width: 'fit-content',
              }}>
              Shop Now
            </Link>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="container-fluid px-lg-5 px-3 my-3">
        <div className="row align-items center">
          <div className="col-lg-12">
            <div className="p-lg-0 p-2 position-relative">
              <div
                class="my-custom-pagination-div"
                style={{
                  position: 'absolute',
                  display: 'flex',
                  paddingBottom: '3rem',
                  justifyContent: 'center',
                  zIndex: 3,
                  listStyle: 'none !important',
                }}
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-items-center py-lg-5 py-3">
          <div className="col-lg-6   pe-lg-3 pb-lg-0 pb-4 ">
            <Link
              to="/category/electronics"
              className="d-flex text-decoration-none align-items-start cat p-4"
              style={{ backgroundColor: 'var(--mdBlue)', height: '468px' }}>
              <div
                className="d-flex flex-column gap-2 p-5"
                style={{ zIndex: '99' }}>
                <p className="text-white">Holiday Deals</p>
                <h2 className="fw-bold text-white display-5 m-0">
                  Up to <br /> 30% off
                </h2>
                <p className="text-white">Selected Smartphone Brands</p>
                <Link
                  to=""
                  style={{ width: 'fit-content' }}
                  className="btn btn-light px-5  py-2 rounded-pill">
                  Shop
                </Link>
              </div>
            </Link>
          </div>
          <div className="col-lg-6  ps-lg-3">
            <Link
              to="/brands"
              className="d-flex text-decoration-none align-items-start brand p-4"
              style={{ backgroundColor: 'var(--mdBlue)', height: '468px' }}>
              <div
                className="d-flex flex-column gap-2 p-5"
                style={{ zIndex: '99' }}>
                <p className="text-white">Just In</p>
                <h2 className="fw-bold text-white display-5 m-0">
                  Take Your <br /> Sound <br />
                  Anywhere
                </h2>
                <p className="text-white">Top Headphone Brands</p>
                <Link
                  to="/category/cosmetics"
                  style={{ width: 'fit-content' }}
                  className="btn btn-light px-5  py-2 rounded-pill">
                  Shop
                </Link>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5 px-3">
        <div className="bg-white py-5">
          <h3 className="m-0 fw-bold fs-2 text-center pb-5">
            Shop By Category
          </h3>
          <div className="d-flex flex-wrap gap-lg-5 gap-3 justify-content-center">
            {categories?.map((category, index) => (
              <Link
                to={`/category/${category?.slug}`}
                key={index}
                className="d-flex justify-content-center align-items-center flex-column gap-2 text-decoration-none">
                <div className="category-item">
                  <img
                    className="rounded-circle w-100 h-100"
                    src={category?.image ? category?.image : img}
                    alt={category?.title}
                  />
                </div>
                <p className="m-0 fs-4 text-dark">{category?.title}</p>
              </Link>
            ))}
            {categories?.map((category, index) =>
              category.subcategories.slice(0, 2).map((subcategory) => {
                if (index !== 0 && index !== 1)
                  return (
                    <Link
                      to={`/category/${category?.slug}`}
                      key={index}
                      className="d-flex justify-content-center align-items-center flex-column gap-2 text-decoration-none">
                      <div className="category-item">
                        <img
                          className="rounded-circle w-100 h-100"
                          src={
                            subcategory?.image
                              ? `https://aswaaq.pythonanywhere.com${subcategory?.image}`
                              : img
                          }
                          alt={subcategory?.title}
                        />
                      </div>
                      <p className="m-0 fs-4 text-dark">{subcategory?.title}</p>
                    </Link>
                  );
              })
            )}
          </div>
        </div>
      </div>
      <MostPopular />
      <TopRated />
      <NewArrivals />
      <Brands />
    </>
  );
}
