import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { CgCloseR } from "react-icons/cg";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import img2 from "../../../assets/Cart/Cart Product Image.png";
import {
  decreaseQuantity,
  increaseQuantity,
  removeFromCart,
} from "../../../redux/reducers/cart";

const CartCard = ({ item, wholesale }) => {
  const [quantity, setQuantity] = useState(0);
  //dispatch
  const dispatch = useDispatch();
  const cart = useSelector((state) => state?.cart?.productsCart);
  console.log("cart", item);
  useEffect(() => {
    const itemInCart = cart?.find((cartItem) => cartItem?.id === item?.id);
    itemInCart ? setQuantity(itemInCart?.quantity) : setQuantity(0);
  }, [cart]);

  const HandleIncreaseQuantity = () => {
    dispatch(increaseQuantity({ id: item?.id, stock: item?.sku?.stock }));
  };
  const HandleDecreaseQuantity = (e) => {
    e.preventDefault();
    dispatch(decreaseQuantity({ id: item?.id }));
  };
  const HandleRemoveFromCart = (e) => {
    e.preventDefault();
    dispatch(removeFromCart({ id: item?.id }));
  };

  return (
    <div
      class="row gx-0 shadow p-3 mb-3 bg-body rounded border-1"
      style={{  borderColor: "#ececec" }}
    >
      <div
        class="col-md-3"
        style={{
          padding: "6px",
        }}
      >
        <img
          src={item?.gallery?.[0]?.image ? item?.gallery?.[0]?.image : img2}
          class="w-100"
          alt="..."
        />
      </div>
      <div class="col-md-8 d-flex justify-content-center align-items-center">
        <div class="card-body p-0 ps-3">
          <div className="d-flex justify-content-between align-items-start mb-3">
            <div>
              <h5
                class="card-title"
                style={{
                  width: "300px",
                  height: " 32px",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "32px",
                  color: "#012A4A",
                }}
              >
                {item?.title?.slice(0, 20)}
                {item?.title?.length > 20 && "..."}
              </h5>
              <p
                class="card-text"
                style={{
                  width: "200px",
                  height: " 16px",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#6C757D",
                }}
              >
                {item?.company?.company_name}
              </p>
            </div>
            <div className="">
              {!wholesale && (
                <CgCloseR
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={HandleRemoveFromCart}
                />
              )}
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-end">
              <div>
                <h5 class="card-title">
                  <div>
                    {!wholesale && (
                      <div
                        className="d-flex justify-content-center align-items-center gap-3"
                        onClick={(e) => e.preventDefault()}
                      >
                        <Button
                          className="btn border-0 text-decoration-none"
                          style={{
                            backgroundColor: "var(--blue)",
                          }}
                          onClick={(e) => HandleDecreaseQuantity(e)}
                        >
                          -
                        </Button>
                        <p className="text-center position-relative p-0 m-0">
                          {quantity}
                        </p>
                        <Button
                          className="btn border-0 text-decoration-none"
                          style={{
                            backgroundColor: "var(--blue)",
                          }}
                          onClick={HandleIncreaseQuantity}
                        >
                          +
                        </Button>
                      </div>
                    )}
                  </div>
                </h5>
              </div>
              {!wholesale && (
                <div
                  style={{
                    fontWeight: 600,
                    fontsize: "22px",
                    lineHeight: "33px",
                    color: "#62A5C2",
                  }}
                >
                  {item?.price * item?.quantity} {item?.currency}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartCard;
