import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import imgUpload from "../../assets/common/upload.png";
import { useState } from "react";
import { GoFile } from "react-icons/go";
import { MdDelete } from "react-icons/md";
import { useEffect } from "react";
import { useSelector } from "react-redux";

//onClose is a function that will be called when the modal is closed
//open is a boolean that will be true when the modal is open
const FinanceModal = ({ onClose, open, setFilesArray, onDone, finance }) => {
  //states
  const [files, setFiles] = useState([]); //array of files
  const [requiredArr, setRequiredArr] = useState([
    'Commercial invoice document',
    'Bill of lading',
    'Export and import licence',
    'Certificate of origin'
  ])
  //redux
  const loading = useSelector((state) => state?.finances?.loading)
  console.log(loading)
  console.log(finance)

  //effects
  useEffect(()=>{
    setFilesArray(files)
  },[files])

  useEffect(()=>{
    setRequiredArr(finance?.note)
  },[finance])

  //onChange
  const onChange = (e) => {
    console.log(e.target.files);
    let files = [];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);
    }
    setFiles(files);
  };



  return (
    <div
      style={{
        width: "1000px",
      }}
    >
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // className="d-flex justify-content-center align-items-center "
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent
          className="py-4"
          style={{
            height: "600px",
            overflow: "auto",
          }}
        >
          <div className="d-flex w-100 justify-content-between  gap-4 w-100 h-100">
            <div
              className="d-flex flex-column justify-content-center  align-items-center w-50"
              style={{
                border: "1px dashed #4A4C89",
                padding: 60,
                borderRadius: 10,
                paddingTop: 100,
                paddingBottom: 100,
              }}
            >
              <img src={imgUpload} alt="uploadPic" />
              <p>Drag and drop files to upload </p>
              <p>or</p>
              <input
                type="file"
                id="upload-file"
                className="d-none"
                onChange={(e) => onChange(e)}
                multiple={true}
              />
              <label
                id="upload-file"
                for="upload-file"
                className="btn text-white"
                style={{
                  backgroundColor: "#97020E",
                  color: "-moz-initial",
                }}
              >
                Browse  
              </label>
            </div>
            <div className="d-flex flex-column w-50 ">
             <div className="mb-3">
             <p  style={{
                  fontWeight: 600,
                  fontSize: 22,
                  color: "#4A4C89",
                }}>Required Files</p>
                {
                  requiredArr?.map((required, index)=>{
                    return(
                      <p className="my-1 p-0" key={index}>{required?.message}</p>
                    )
                  })
                }
                {
                  requiredArr?.length === 0 && <p>No Required Fles Found</p>
                }
             </div>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: 22,
                  color: "#4A4C89",
                }}
              >
                Uploaded Files
              </p>
              {files?.map((file, index) => {
                return (
                  <div className="d-flex gap-4 mb-4 align-items-center justify-content-between">
                    <div className="d-flex gap-2">
                      <p className="p-0 m-0">
                        <GoFile size={20} />
                      </p>
                      <p className="p-0 m-0">{file?.name}</p>
                    </div>
                    <div>
                      <MdDelete
                        size={20}
                        color="red"
                        onClick={() => {
                          let newFiles = [...files];
                          newFiles.splice(index, 1);
                          setFiles(newFiles);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="w-100 d-flex align-items-end h-100">
                <button
                  className="btn text-white w-100 rounded-2"
                  style={{
                    backgroundColor: "#1D1F6C",
                  }}
                 onClick={onDone}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FinanceModal;
