import React from 'react';
import { Link } from 'react-router-dom';

const LanguageDropdown = () => {
  return (
    <>
      <div className="dropdown">
        <button
          className="btn fsSmall d-flex gap-2 align-items-center text-white border-0 dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          EN
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <Link className="dropdown-item" to="">
              English
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="">
              Arabic
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="">
              French
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LanguageDropdown;
